<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceAnalysis-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
       <div class="common-input-container">
        <span>基准值:</span>
        <el-select
          class="common-screen-input"
          v-model="params.referenceType"
          @change="commonFun"
          placeholder="请选择"
          filterable
        >
          <el-option key="other" label="客户" value="other"> </el-option>
          <el-option key="system" label="系统" value="system"> </el-option>
        </el-select>
      </div>
      <!-- <div class="common-input-container">
        <el-date-picker
          v-model="params.datePicker"
          :clearable="false"
          @change="
            () => {
              changeDate();
            }
          "
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="common-input-container">
        <el-radio-group v-model="params.type" @change="changeRadio">
          <el-radio-button :label="1">近7天</el-radio-button>
          <el-radio-button :label="2">近30天</el-radio-button>
          <el-radio-button :label="3">近90天</el-radio-button>
          <el-radio-button :label="0">昨天</el-radio-button>
        </el-radio-group>
      </div> -->
      <div class="common-input-container">
        <common-lately-date @commonGetDate="commonGetDate" :commonRadio="commonRadio" :startDate="params.startDate" :endDate="params.endDate" ref="commonReset"></common-lately-date>
      </div>
    </div>
    <el-table class="common-table" :data="tableData" @sort-change="tableSort">
      <el-table-column
        fixed
        prop="area"
        label="分公司(地区)"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column fixed prop="serviceName" label="客服名称" width="120px">
        <template slot-scope="scope">
          <span class="nameBtn" @click="() => nameBtn(scope.row.adminId)">{{
            scope.row.serviceName || "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed
        prop="shopName"
        label="	店铺名称"
        :formatter="tableColumn"
        width="160px"
      ></el-table-column>
       <el-table-column
        prop="consultationNum"
        label="总咨询量"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column label="满意度" sortable="custom" prop="satisfaction" width="120px">
        <template slot-scope="scope">
          <span :class="scope.row.satisfactionFlag == '0' && scope.row.satisfaction ? 'warning' : ''">{{
            scope.row.satisfaction || "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="evaluateDeliveryRate"
        label="	E客服评价发送率"
        sortable="custom"
        width="160px"
      >
        <template slot-scope="scope">
          <span>
            {{
              scope.row.evaluateDeliveryRate
                ? (scope.row.evaluateDeliveryRate * 100).toFixed(2) == "--"
                  ? "--"
                  : (scope.row.evaluateDeliveryRate * 100).toFixed(2) + "%"
                : "--"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="evaluateVerySatisfaction"
        :formatter="tableColumn"
        sortable="custom"
        label="E客服评价很满意"
        width="160px"
      ></el-table-column>
      <el-table-column
        label="E客服评价满意"
        sortable="custom"
        prop="evaluateSatisfaction"
        :formatter="tableColumn"
        width="160px"
      >
      </el-table-column>
      <el-table-column
        prop="evaluateCommonly"
        :formatter="tableColumn"
        label="E客服评价一般"
        sortable="custom"
        width="160px"
      ></el-table-column>
      <el-table-column
        prop="evaluateDissatisfied"
        :formatter="tableColumn"
        label="	E客服评价不满"
        sortable="custom"
        width="160px"
      ></el-table-column>
      <el-table-column
        prop="evaluateVeryDissatisfied"
        :formatter="tableColumn"
        label="E客服评价很不满"
        sortable="custom"
        width="160px"
      ></el-table-column>
      <el-table-column
        prop="evaluateSatisfactionDifference"
        :formatter="tableColumn"
        label="	客户满意度差值"
        sortable="custom"
        width="160px"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <satisfied-dialog ref="satisDialog"></satisfied-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import SatisfiedDialog from "./children/satisfiedDialog.vue";
import { satisfactionSource } from "../../service/dataInfo.js";
import { tableColumn, DateTransform, getLatelyDay } from "../../utils/index.js";
import CommonLatelyDate from "../../components/common/commonLatelyDate.vue"
export default {
  components: { Breadcrumb, SatisfiedDialog, CommonLatelyDate },
  data() {
    return {
      loading: false,
      tableColumn,
      DateTransform,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "满意度分析", isLink: true, url: "/data/customer-service-satisfied" },
        { title: "满意度数据源", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      commonRadio: this.$route.query.commonRadio,
      params: {
        companyId: this.$route.query.companyId,
        shopId: this.$route.query.shopId,
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
        referenceType: this.$route.query.referenceType,
        page: 1,
        pageSize: 10,
      },
      centerDialogVisible: false,
      tableData: [],
      pickerOptions: {
        // 指派分公司的禁用时间范围
        // disabledDate: (time) => {
        //   return (
        //     time.getTime() <
        //       new Date(this.$route.query.startDate).getTime() - 24 * 60 * 60 * 1000 ||
        //     time.getTime() > new Date(this.$route.query.endDate).getTime()
        //   );
        // },
      },
    };
  },
  created() {
    this.satisfactionSource();
  },
  methods: {
    commonGetDate (startTime, endTime, commonRadio) {
      this.params.startDate = startTime
      this.params.endDate = endTime
      this.commonRadio = commonRadio
      this.satisfactionSource()
    },
    nameBtn(adminId) {
      let params = { ...this.params };
      params.adminId = adminId;
      if (params.pageSize) {
        delete params.pageSize;
      }
      if (params.page) {
        delete params.page;
      }
      this.$refs["satisDialog"].getParams(params, adminId, this.commonRadio || null);
    },
    tableSort(e) {
      // 表格项排序
      let params = { ...this.params };
      params.sortField = e.prop;
      console.log(e.prop);
      switch (e.order) {
        case "ascending":
          params.sortType = 1;
          break;
        case "descending":
          params.sortType = 2;
          break;
        default:
          params.sortType = null;
      }
      this.params = params;
      this.satisfactionSource();
    },
    changeDate() {
      // 选择时间时清空近几天类型选择
      this.params.type = null;
      this.satisfactionSource();
    },
    changeRadio(e) {
      let date;
      switch (e) {
        case 0:
          date = 0;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.params.datePicker = getLatelyDay(date);
      this.satisfactionSource();
    },
    changeBreadcrumb(num) {
      // 面包屑点击事件
      this.nowBreadcrumb = num;
    },
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.satisfactionSource();
    },
    handleSelectionChange(row) {
      console.log(row);
    },
    resetBtn() {
      this.params = {
        shopId: this.$route.query.shopId,
        type: this.$route.query.type, // 筛选类型 今天/30天/90天 ...
        datePicker: [
          new Date(this.$route.query.startDate),
          new Date(this.$route.query.endDate),
        ], // 时间范围选择-默认当天
        page: 1,
        pageSize: 10,
      };
      this.satisfactionSource();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.satisfactionSource();
    },
    async satisfactionSource() {
      // 子账号列表
      this.loading = true;
      let params = { ...this.params };
      if (params.datePicker && params.datePicker.length) {
        params.startDate = DateTransform(params.datePicker[0]);
        params.endDate = DateTransform(params.datePicker[1]);
      }
      if (!params.type) {
        delete params.type;
      }
      if (!params.sortType && params.sortType != 0) {
        delete params.sortType;
      }
      let resData = (await satisfactionSource({ ...params })).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.serviceAnalysis-container {
  text-align: left;
  .nameBtn {
    color: #409eff;
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
  .warning {
    color: #f00;
  }
  .breadcrumbStyle {
    color: #00000073;
    font-weight: 500;
  }
  .breadcrumbCursor {
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}
</style>
